import React from "react"

import OverviewComponent from "../OverviewComponent"

export default function Wikigrads({ data }) {
  let i = 0
  return [
    // <section className='white-bg Wikigrads main' key={i++}>
    //   <div className='transformBlock'></div>
    //   <div className='bgBlock bgImage Wikigrads' style={{height: '100%'}}>
    //     <div className="container" id='wikigrads-container'>
    //       <div className='row'>
    //         <div className='col-sm-6' style={{height: '100%'}}>
    //           <div className='titleBlock'>
    //             <div className='titleBlock_logo'>
    //               <img src={data.images.logo} alt='Wikigrads logotype' />
    //             </div>
    //             <div className='titleBlock_text'>
    //               <h1>{data.title}</h1>
    //               <span id='wikigrads-subheader'>{data.subtitle}</span>
    //             </div>
    //           </div>
    //         </div>
    //         <div className='col-sm-6 titleImage'>
    //           <div className='titleImage__bg'></div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>,

    <OverviewComponent
      {...data}
      key={i++}
      classComponent="white-bg Wikigrads"
    />,

    <section className="solution Wikigrads" key={i++}>
      <div className="solutionImgBg">
        <img src={data.images.picture2} alt="Wikigrads picture" />
      </div>
    </section>,

    <section className="white-bg solution first" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2>{data.solution.title}</h2>
            {data.solution.textFirst.map((item, i) => (
              <p className="mb-4" key={i}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </section>,

    <section className="white-bg" key={i++}>
      <div className="image fixed">
        <img src={data.images.picture3} alt="Wikigrads application" />
      </div>
    </section>,

    <section className="solution last white-bg" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h2 className="noMarginTop">{data.delivered.title}</h2>
          </div>
          <div className="col-md-7">
            <ul>
              {data.delivered.list.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
          <div className="col-md-5">
            <img
              src={data.images.picture4}
              alt="CartoVera application"
              style={{ paddingLeft: "32px", paddingTop: "32px" }}
            />
          </div>
        </div>
      </div>
    </section>,
  ]
}
