import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import WikigradsHeader from "../../components/SingleCase/Wikigrads/WikigradsHeader"
import Wikigrads from "../../components/SingleCase/Wikigrads"
import ContactBtnBlock from "../../components/SingleCase/ContactBtnBlock"
import Navigate from "../../components/SingleCase/Navigate"
import RelatedProjects from "../../components/SingleCase/RelatedProjects"
import dataForWikigrads from "../../information/Cases/dataForWikigrads"
import { mainUrl } from "../../js/config"
import "../../components/SingleCase/PortfolioComponent.scss"

export default class WikigradsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showForm: false,
    }
  }

  toggleForm = () => {
    this.setState({ showForm: !this.state.showForm })
  }
  render() {
    const {
      classComponent,
      nextLink,
      prevLink,
      relatedProjects,
      data,
    } = dataForWikigrads

    return (
      <section className="singleProject">
        <SEO
          title="Wikigrads Development Case — OS-System"
          description='Learning platform development case - Wikigrads - how to create an application for interactive learning environment for students and teachers'
          canonical={`${mainUrl}/cases/wikigrads/`}
        />
        <Layout showFormPoint={100}>
          <WikigradsHeader data={data} />
          <Wikigrads data={data} />
          <Navigate
            classComponent={classComponent}
            nextLink={nextLink}
            prevLink={prevLink}
          />
          <ContactBtnBlock />
          <RelatedProjects relatedProjects={relatedProjects} />
        </Layout>
      </section>
    )
  }
}
