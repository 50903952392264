import React from "react"

import "./WikigradsHeader.scss"

export default function WikigradsHeader({ data }) {
  const { images, title, subtitle } = data
  return (
    <section className="wikigrads-header">
      <div className="wikigrads-header-wrapper">
        <div className="wikigrads-header-info-block">
          <div className="wikigrads-header-transform-block"></div>
          <div className="wikigrads-header-logo-container">
            <img
              src={images.logo}
              alt="Wikigrads logotype"
              className="wikigrads-header-logo"
            />
          </div>
          <div className="wikigrads-header-text-block">
            <h1 className="wikigrads-header-title">{title}</h1>
            <span className="wikigrads-header-subtitle">{subtitle}</span>
          </div>
        </div>
        <div className="wikigrads-header-computer-container">
          <img
            src={images.computer}
            alt="Computer"
            className="wikigrads-header-computer"
          />
        </div>
      </div>
    </section>
  )
}
