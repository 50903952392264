import {
  Figures,
  Logo,
  Computer,
  MainPicture,
  Picture2,
  Picture3,
  Picture4,
} from "../../images/SingleCase/Wikigrads"

const dataForWikigrads = {
  type: "project",
  smart: true,
  classComponent: "Wikigrads",
  nextLink: "cases/karpatska-chaika/",
  prevLink: "cases/video-conference/",
  data: {
    title: "Wikigrads",
    subtitle:
      "A free social learning platform to enable students in higher education to learn, collaborate and engage more effectively. The platform supplements and enhances traditional lectures by providing an interactive and student-centered environment that structures and drives academic progress and fosters personal achievements.",
    overview: {
      title: "Project overview",
      about: {
        title: "About client",
        content:
          "Our client is a startup from Los Angeles, USA. The company was founded in 2012 and was one of the first full-scale e-learning startups. Their main product goal is to create a peer-to-peer (P2P) learning platform that helps instructors organically blend better pedagogy into online instruction.",
      },
      challenge: {
        title: "Business challenge",
        content:
          "In the beginning of the project the main target was the UI and UX improvement of the current web-application but after some time project has grown in full-scale application upgrade from v1.0.4 to v1.0.20 version and than to v1.1.0 (final version). During 3 years of close collaboration all efforts were focused on e-learning application improvement by increasing functionality for students and teachers worldwide.",
      },
      specific: [
        {
          type: "Platform",
          content: ["Web"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: [
            "AWS",
            "Symfony 1.4",
            "doctrine",
            "PHP",
            "MySQL",
            "ZeroMQ",
            "CSS",
            "JavaScript",
            "HTML",
          ],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Project Manager",
              count: 1,
            },
            {
              type: "PHP Developers",
              count: 2,
            },
            {
              type: "QA Engineer",
              count: 1,
            },
            {
              type: "Designer",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "months",
              count: 36,
            },
          ],
        },
        {
          type: "Client",
          content: ["Startup"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        'The application can oversee the sharing of multiple representations of course material from multiple perspectives and digital contexts. P2P engagement is created by a live activity stream that encourages dialogue and content sharing - the basic building blocks of deeper pedagogical and literacy practices.',
        'The many-to-many interactivity of a live stream creates a media-centric dialogue that can amplify levels of engagement and participation far beyond any other kind of information and communications technology. Unleash strategies that build dialogic, digital, and participatory literacies, and organically support deeper learning, "personalized learning", active learning, collaboration, higher-order thinking, and equity in education.',
        'Assess or grade student understandings of multimodal content-documents, web media, images, or LaTeX - in collaborative or individual contexts. In large classes, a P2P space can be entirely student-driven, leveraging differences in understanding among the students themselves.',
      ],
    },
    delivered: {
      title: "Value delivered",
      list: [
        "Additional user roles: Officer and Admin",
        "Course Streams where teacher and students can talk and share docs, pictures",
        "Private Streams where student can talk to teacher without involving other students",
        "Notification system implementation",
        "Possibility to post documents, images, videos and links",
        "Links posting is quite similar to Facebook style",
        "With prefetching page title and image",
        "Formulas editor LaTex implementation",
        "Realtime course stream update based on WebSockets and PHP library Ratchet and messaging queue library ZeroMQ",
        "Integration with Learning Management Systems (LMS) via Learning Tools Interoperability (LTI) standard. Currently can work with Moodle, Sakai and BlackBoard. Integration allows to share profiles from LMS to WikiGrads",
        "UI and UX improvement + responsive design implementation",
        "Application performance and cross-browser compatibility improvement (Google Chrome, Safari, Firefox, Opera, IE)",
        "More flexible filter system implemented",
        "Statistic gathering, calculation and display-page implementation",
        "Site navigation improvement",
        "Interactive online tutorial implementation",
      ],
    },
    images: {
      mainPicture: MainPicture,
      logo: Logo,
      computer: Computer,
      figurs: Figures,
      picture2: Picture2,
      picture3: Picture3,
      picture4: Picture4,
    },
  },
  imageSrc: [],
  relatedProjects: [
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
    {
      link: "/cases/crm-sap-integration",
      id: "crmSap",
      title: "crmSap",
    },
    {
      link: "/cases/cartovera",
      id: "cartovera",
      title: "Cartovera",
    },
    {
      link: "/cases/service-doc",
      id: "service-doc",
      title: "Service Doc",
    },
  ],
  link: "/cases/wikigrads",
}

export default dataForWikigrads
